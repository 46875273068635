import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    clientID: 0,
    name: '',
    avatarUrl: '',
    accessToken: ''
}

export const contextSlice = createSlice({
    name: 'context',
    initialState,
    reducers: {
        login: (state, action) => {
            state.clientID = action.payload.id
            state.name = action.payload.global_name
            state.avatarUrl = action.payload.avatar
            state.accessToken = action.payload.access_token
        },
        logout: (state) => {
            state.clientID = 0
            state.name = ''
            state.avatarUrl = ''
            state.accessToken = ''
        }
    }
})

export const { login, logout } = contextSlice.actions;
export default contextSlice.reducer

