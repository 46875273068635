import React, { Component, useEffect, useState } from 'react'
import './Navbar.css'
import '../styles/styleMobile.scss'
import { NavLink } from "react-router-dom";
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightFromBracket, faBars, faXmark, faRightToBracket, faBoxOpen, faHome, faCircleUser } from '@fortawesome/free-solid-svg-icons'
import { faDiscord } from '@fortawesome/free-brands-svg-icons'
import { login, logout } from '../storeSlice/contextSlice/Context';
import Moment from 'moment-timezone';

const Navber = () => {

    const [click, setClick] = useState(false)
    const handleClick = () => setClick(!click)
    const closeMobileMenu = () => setClick(false)
    const [showLogin, setShow] = useState(false)
    const [userlogin, setLogin] = useState(true)
    const [image, setImage] = useState(null);
    const dispatch = useDispatch()
    const data = useSelector((state) => state);
    const logo = "https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/Logo_MRB_SHOP_01-01.png?alt=media&token=23d428c3-d679-405e-8b54-e4f75c5e2ac1"

    const handleLogin = () => {
        window.location.href = 'https://discord.com/api/oauth2/authorize?client_id=440504729261572106&redirect_uri=https%3A%2F%2Fmrb-developer.com&response_type=code&scope=identify%20connections%20email';
        setClick(false)
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');

        if (code) {
            axios.get(`https://work:3000/api/callback?code=${code}`)
                .then((response) => {
                    const user = JSON.parse(response.data.data);
                    dispatch(login(user))
                })
                .catch((error) => {
                });
        }

        if (!data.clientID) {
            return
        }

        if (data.clientID !== 0) {
            setShow(true)
            setLogin(false)

            const cleanedClientID = data.clientID.replace(/"/g, '');
            const cleanedAvatarUrl = data.avatarUrl.replace(/"/g, '');

            if (!data.avatarUrl) {
                setImage(null)
                return
            }

            setImage(`https://cdn.discordapp.com/avatars/${cleanedClientID}/${cleanedAvatarUrl}.png`)
        }

        if (!data.accessToken) {
            dispatch(logout())
        }

    }, [data.clientID]); // [data.clientID] คือ เมื่อค่า data.clientID เปลี่ยน (เช่น หลังจากที่ได้รับข้อมูลผู้ใช้แล้ว) useEffect จะถูกเรียกใช้ใหม่ ซึ่งทำให้ส่วนของโค้ดใน useEffect ก็จะทำงานอีกครั้งตามที่ได้กำหนดไว้

    const clickUser = () => {
        setClick(false)
        window.location = '/profile'
    }


    const checkLogout = () => {
        setShow(false)
        setLogin(true)
        dispatch(logout())
        setClick(false)
    }

    return (
        <div className='from-navbar'>
            <NavLink to='/'>
                <div className='card-logo'>
                    <span>
                        <img className='logo' src={logo} />
                        <p className='text-amber-200'>MRB SHOP</p>
                    </span>
                </div>
            </NavLink>
            <NavLink to='/' className={({ isActive }) =>
                isActive ? 'navbar-logo active-navbar-logo' : 'navbar-logo'
            }>
                <p>Home</p>
                <img className='logo' src={logo} />
            </NavLink>
            <div className={click ? 'section active' : 'section'}>
                <NavLink to='/product' onClick={closeMobileMenu} className={({ isActive }) =>
                    isActive ? 'navbar-home active-navbar-home' : 'navbar-home'
                }>
                    <FontAwesomeIcon icon={faBoxOpen} className='icon-navbar'></FontAwesomeIcon>
                    <p>สคริปต์</p>
                </NavLink>
                <NavLink to='/' onClick={closeMobileMenu} className={({ isActive }) =>
                    isActive ? 'navbar-home' : 'navbar-home'
                }>
                    <FontAwesomeIcon icon={faDiscord} className='icon-navbar' />
                    <p>Discord</p>
                </NavLink>
                <p className='bookmark'>|</p>
                {userlogin ? (
                    <NavLink onClick={handleLogin} className={({ isActive }) => isActive ? 'navbar-login' : 'navbar-login'}>
                        <FontAwesomeIcon icon={faRightToBracket} className='icon-navbar'></FontAwesomeIcon>
                        <span>เข้าสู่ระบบ</span>
                    </NavLink>
                ) : null}
                {showLogin ? (
                    <>
                        <p className='text-name max-w-[30%] overflow-hidden whitespace-nowrap text-ellipsis text-[15px] pt-[5px]'>{data.name}</p>
                        <div
                            onClick={clickUser}
                            className='image-user w-[2.5vw] h-[2.5vw] bg-white backdrop-blur-sm bg-white/30 rounded-[50vw] flex justify-center items-center cursor-pointer'>
                            {image ? (
                                <>
                                    <img className='object-cover w-[2.2vw] h-[2.2vw] rounded-[50vw]' src={image} alt="" />
                                </>
                            ) : (
                                <>
                                    <FontAwesomeIcon
                                        icon={faCircleUser}
                                        className='icon-user-login w-[2.2vw] h-[2.2vw]'></FontAwesomeIcon>
                                </>
                            )}


                        </div>
                        <FontAwesomeIcon
                            icon={faRightFromBracket}
                            onClick={() => checkLogout()}
                            className='icon-logout transition ease-in-out delay-75 hover:scale-[1.1] duration-300 cursor-pointer'></FontAwesomeIcon>
                    </>
                ) : null}
            </div>
            <div className='menu' onClick={handleClick}>
                {click ? (
                    <FontAwesomeIcon icon={faXmark} className='icon-menu'></FontAwesomeIcon>
                ) : (
                    <FontAwesomeIcon icon={faBars} className='icon-menu'></FontAwesomeIcon>
                )}
            </div>
        </div>
    )

}

export default Navber;
