import React, { useState, useEffect } from 'react'
import '../styles/Home.css'
import '../styles/styleMobile.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTurnDown, faBahtSign, faSpinner } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import listScripts from '../models/user';
import { useSelector, useDispatch } from 'react-redux';
import { login, logout } from '../storeSlice/contextSlice/Context';
import { NavLink } from "react-router-dom";
import Moment from 'moment-timezone';

const Home = () => {

    const [loading, setLoading] = useState(false);
    const [textLogin, setTextLogin] = useState(true);
    const dispatch = useDispatch()
    const data = useSelector((state) => state);
    const scriptNews = Object.values(listScripts.news);
    const scriptAdvice = Object.values(listScripts.advice);


    const handleLogin = () => {

        if (textLogin === true) {
            window.location.href = 'https://discord.com/api/oauth2/authorize?client_id=440504729261572106&redirect_uri=https%3A%2F%2Fmrb-developer.com&response_type=code&scope=identify%20connections%20email';
            // window.location.href = 'https://discord.com/api/oauth2/authorize?client_id=440504729261572106&redirect_uri=http%3A%2F%2Flocalhost%3A3000&response_type=code&scope=identify%20connections';

        } else if (textLogin === false) {
            window.location.href = 'https://discord.gg/3JrFf6FcXe'
        }
    };

    useEffect(() => {
        setTextLogin(false)
        // setLoading(true)
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        if (code) {
            axios.get(`https://mrbinsertip.work:3000/api/callback?code=${code}`)
                .then((response) => {
                    const user = JSON.parse(response.data.data);
                    dispatch(login(user))
                    // setLoading(false)
                })
                .catch((error) => {
                    // setLoading(false) 
                    // console.log('%c⧭', 'color: #00736b', error);
                });
        }
        if (data.clientID === 0) {
            setTextLogin(true)
        } else (
            setLoading(false)
        )

        if (!data.accessToken) {
            dispatch(logout())
        } 


    }, [data.clientID]);

    const noGetData = (index, name) => {
        let dataView = {
            index: index,
            label: name
        }

        localStorage.setItem("dataView", JSON.stringify(dataView));
        window.location = '/viewProduct'

    }

    return (
        <div className='from-home'>
            {loading && (
                <div className='fixed gap-[1vw] top-0 left-0 w-[100%] h-[100%] z-[1000] flex justify-center items-center bg-black bg-opacity-50'>
                    <FontAwesomeIcon icon={faSpinner} className='icon-spinner animate-spin '></FontAwesomeIcon>
                    load...
                </div>
            )}
            <div className='card-body mt-[30px]'>
                <img className='logo-home' src='https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/Logo_MRB_SHOP_01-01.png?alt=media&token=23d428c3-d679-405e-8b54-e4f75c5e2ac1' />
                <span className='flex flex-col gap-[50px]'>
                    <div className='text-center h-[2vw] mt-[auto] mb-[auto] mr-[auto] ml-[auto]'>
                        <h1 className='text-[4vw] font-bold text-center text-amber-400'>MRB SHOP</h1>
                        <p className='text-haeder text-[1vw] pt-[1vw] text-sky-600'>Script for you!</p>
                        <p className='text-haeder text-[1vw]'>Sell high quality scripts at an affordable price.</p>
                    </div>
                    <div className='card-login-discord relative bg-white backdrop-blur-sm bg-white/20 p-[1vw]'>
                        {textLogin ? (
                            <span className='absolute bottom-[5.2vw] left-[1vw] flex justify-center items-center gap-[.3vw] bg-white p-[.1vw] px-[.5vw] rounded-[.5vw]'>
                                <p className='text-[.8vw] font-bold text-black'>เข้าสู่ระบบ</p>
                                <FontAwesomeIcon icon={faTurnDown} className='icon-turndown text-[.6vw]'></FontAwesomeIcon>
                            </span>
                        ) : (
                            <span className='absolute bottom-[5.2vw] left-[1vw] flex justify-center items-center gap-[.3vw] bg-white p-[.1vw] px-[.5vw] rounded-[.5vw]'>
                                <p className='text-[.8vw] font-bold text-black'>Join Discord</p>
                                <FontAwesomeIcon icon={faTurnDown} className='icon-turndown text-[.6vw]'></FontAwesomeIcon>
                            </span>
                        )}
                        <span className='flex justify-center items-center gap-[.5vw] cursor-pointer bg-[#7389da] hover:bg-[#647dda] p-[1vw] px-[2vw] rounded-[1vw] shadow-lg transition ease-in-out delay-75 hover:scale-[.9] duration-300'
                            onClick={handleLogin}>
                            <img className='logo-discord w-[2.5vw]' src='https://th.bing.com/th/id/R.f1d96474cc6a663c8f6e9e44ea25fdd5?rik=kU69GqlKxRxC5Q&pid=ImgRaw&r=0' />
                            <img className='logo-discord w-[5vw]' src='https://clipground.com/images/white-discord-logo-9.png' />
                        </span>
                    </div>
                </span>
            </div>

            <div className='grid-script grid gap-[2vw] w-[60vw] mr-[auto] ml-[auto] mt-[2vw]'>
                <span className='flex items-center gap-[43vw]'>
                    <div className='card-section-product w-[9vw] p-[.5vw] rounded-[2vw]'>
                        <h2 className='text-center text-[1vw] font-bold'>สินค้าแนะนำ</h2>
                    </div>
                    <NavLink to='/product' className={({ isActive }) => isActive ? 'view-all-product' : 'view-all-product'}>
                        <span>ดูเพิ่มเติม</span>
                    </NavLink>
                </span>
                <div className='grid-product grid grid-cols-3 pb-[2vw]'>
                    {scriptAdvice.map((item, index) => (
                        <div className='scroll m-[0]' key={index}>
                            <div onClick={() => noGetData(index, 'scriptIntroduce')} className='card-product grid grid-rows-2 gap-[10px] backdrop-blur-sm bg-white/30 w-[90%] h-[100%] bg-white p-[.5vw] rounded-[1vw] cursor-pointer overflow-hidden mr-[auto] ml-[auto] transition ease-in-out delay-75 hover:-translate-y-2 hover:scale-[1] duration-300'>
                                <img className='w-[100%] h-[200px] object-cover rounded-[1vw]' src={item.img} />
                                <div className='card-details w-[100%] h-[100%] rounded-[.5vw] bg-white text-black p-[.5vw] grid gap-[.5vw] backdrop-blur-sm bg-white/30'>
                                    <p className='text-[1.4vw] font-bold text-violet-900'>{item.label}</p>
                                    <p className='pl-[.5vw]'>{item.details.split('\n')[0]}</p>
                                    <div className='w-[6vw] h-[35px] flex items-center justify-end pr-[10px] p-[1%] bg-gradient-to-r from-violet-950 to-violet-900 rounded-r-lg relative right-[1.1vw]'>
                                        <p className='text-price text-[1vw] font-bold text-white'>{Number(item.price).toLocaleString()} <FontAwesomeIcon icon={faBahtSign}></FontAwesomeIcon></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className='grid-script grid gap-[2vw] w-[60vw] mr-[auto] ml-[auto] mt-[2vw]'>
                <span className='flex items-center gap-[43vw]'>
                    <div className='card-section-product w-[9vw] p-[.5vw] rounded-[2vw]'>
                        <h2 className='text-center text-[1vw] font-bold'>สินค้าใหม่ล่าสุด</h2>
                    </div>
                    <NavLink to='/product' className={({ isActive }) => isActive ? 'view-all-product' : 'view-all-product'}>
                        <span>ดูเพิ่มเติม</span>
                    </NavLink>
                </span>
                <div className='grid-product grid grid-cols-3 pb-[5vw]'>
                    {scriptNews.map((item, index) => (
                        <div className='scroll m-[0]' key={index}>
                            <div onClick={() => noGetData(index, 'scriptNew')} className='card-product grid grid-rows-2 gap-[10px] backdrop-blur-sm bg-white/30 w-[90%] h-[100%] bg-white p-[.5vw] rounded-[1vw] cursor-pointer overflow-hidden mr-[auto] ml-[auto] transition ease-in-out delay-75 hover:-translate-y-2 hover:scale-[1] duration-300'>
                                <img className='w-[100%] h-[200px] object-cover rounded-[1vw]' src={item.img} />
                                <div className='card-details w-[100%] h-[100%] rounded-[.5vw] bg-white text-black p-[.5vw] grid gap-[.5vw] backdrop-blur-sm bg-white/30'>
                                    <p className='text-[1.4vw] font-bold text-violet-900'>{item.label}</p>
                                    <p className='pl-[.5vw]'>{item.details.split('\n')[0]}</p>
                                    <div className='w-[6vw] h-[35px] flex items-center justify-end pr-[10px] p-[1%] bg-gradient-to-r from-violet-950 to-violet-900 rounded-r-lg relative right-[1.1vw]'>
                                        <p className='text-price text-[1vw] font-bold text-white'>{Number(item.price).toLocaleString()} <FontAwesomeIcon icon={faBahtSign}></FontAwesomeIcon></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        </div>
    )
}

export default Home;
