import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route, } from 'react-router-dom';
import LayoutNavbar from './pages/LayoutNavbar';
import Product from './pages/Product';
import Home from './pages/Home';
import ViewProduct from './pages/ViewProduct';
import { DataProvider } from './contexts/DataContext';
import { store, persistor } from './storeSlice/store'
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Profile from './pages/Profile';

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Routes>
              <Route path='/' element={<LayoutNavbar />}>
                <Route index element={<Home />} />
                <Route path='product' element={<Product />} />
                <Route path='viewProduct' element={<ViewProduct />} />
                <Route path='profile' element={<Profile />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
