import React, { useEffect } from 'react'
import '../styles/styleMobile.scss'
import listScripts from '../models/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBahtSign, faBoxOpen } from '@fortawesome/free-solid-svg-icons'

const Product = () => {

    const scriptAdvice = Object.values(listScripts.all);

    const noGetData = (index, name) => {

        let dataView = {
            index: index,
            label: name
        }

        localStorage.setItem("dataView", JSON.stringify(dataView));
        window.location = '/viewProduct'

    }

    return (
        <div className='from-product'>
            <div className='header w-[100%] pt-[100px] px-[10px]'>
                <div className='card-header mr-[auto] ml-[auto] w-[10%] p-[.5vw] rounded-[2vw]'>
                    <p className='text-white font-bold text-[1vw] text-center flex gap-[10px] justify-center items-center'>
                        <FontAwesomeIcon icon={faBoxOpen} className='icon-box'></FontAwesomeIcon>
                        สคริปต์</p>
                </div>

                <div className='grid-product py-[4%] flex flex-wrap gap-[35px] justify-center px-[10vw]'>
                    {scriptAdvice.map((item, index) => (
                        <div className='scroll m-[0]' key={index}>
                            <div onClick={() => noGetData(index, 'scriptAll')} className='card-product grid grid-rows-2 gap-[10px] backdrop-blur-sm bg-white/30 w-[100%] h-[100%] bg-white p-[.5vw] rounded-[1vw] cursor-pointer overflow-hidden mr-[auto] ml-[auto] transition ease-in-out delay-75 hover:-translate-y-2 hover:scale-[1] duration-300'>
                                <img className='w-[20rem] h-[200px] object-cover rounded-[1vw]' src={item.img} />
                                <div className='card-details w-[20rem] rounded-[.5vw] bg-white text-black p-[.5vw] grid gap-[.5vw] backdrop-blur-sm bg-white/30 '>
                                    <p className='text-[1.4vw] font-bold text-violet-900'>{item.label}</p>
                                    <p className='pl-[.5vw]'>{item.details.split('\n')[0]}</p>
                                    <div className='w-[6vw] h-[35px] flex items-center justify-end pr-[.6vw] text-right bg-gradient-to-r from-violet-950 to-violet-900 rounded-r-lg relative right-[1.1vw]'>
                                        <p className='text-price text-[1vw] font-bold text-white'>{Number(item.price).toLocaleString()} <FontAwesomeIcon icon={faBahtSign}></FontAwesomeIcon></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )

}

export default Product
